import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from '../../../i18n';

const ShortDescriptionText = (props) => {
    const { t, service } = props;
    return t('services.' + service.id + '.shortDescription');
};

ShortDescriptionText.propTypes = {
    service: PropTypes.object.isRequired,
};

export default withTranslation('services')(ShortDescriptionText);
