import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import CheckMark from './Checkmark';
import Icon from './Icon';
import LabelWithFullDescription from './LabelWithFullDescription';
import LabelWithFullDescriptionUnderneathAndExistingClientsOnly from './LabelWithFullDescriptionUnderneathAndExistingClientsOnly';
import LabelWithHomepageDescription from './LabelWithHomepageDescription';
import LabelWithShortDescriptionUnderneath from './LabelWithShortDescription';
import LabelWithShortDescriptionUnderneathAndExistingClientsOnly from './LabelWithShortDescriptionUnderneathAndExistingClientsOnly';
import DropdownLabelText from './Labels/DropdownLabelText';
import LabelText from './Labels/LabelText';
import Price from './Price';

const Wrapper = styled.div`
    display: flex;
    align-items: ${(props) => props.alignItems};
    gap: 1rem;
    flex-wrap: nowrap;
    max-width: 100%;
    line-height: 1.5rem;
`;

export const ServiceDescription = styled.div`
    color: #939393;
    margin-top: 0.5rem;
    font-size: 0.9rem;
`;

class Service extends PureComponent {
    static Icon = Icon;
    static Label = LabelText;
    static DropdownLabel = DropdownLabelText;
    static LabelWithShortDescriptionUnderneath = LabelWithShortDescriptionUnderneath;
    static LabelWithFullDescriptionUnderneath = LabelWithFullDescription;
    static LabelWithFullDescriptionUnderneathAndExistingClientsOnly =
        LabelWithFullDescriptionUnderneathAndExistingClientsOnly;
    static LabelWithShortDescriptionUnderneathAndExistingClientsOnly =
        LabelWithShortDescriptionUnderneathAndExistingClientsOnly;
    static LabelWithHomepageDescriptionUnderneath = LabelWithHomepageDescription;
    static Price = Price;

    static Checked = CheckMark;

    handleClick = () => {
        const { service, onClick } = this.props;
        if (onClick) {
            onClick(service);
        }
    };

    render() {
        const { children, service, currency, className, alignItems = 'start' } = this.props;

        const propsToPassDown = {
            service,
            currency,
        };

        return (
            <Wrapper alignItems={alignItems} onClick={this.handleClick} key={service.id} className={className}>
                {React.Children.map(children, (child) => child && React.cloneElement(child, propsToPassDown))}
            </Wrapper>
        );
    }
}

Service.propTypes = {
    className: PropTypes.string,
    service: PropTypes.object.isRequired,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    alignItems: PropTypes.oneOf(['center', 'start', 'end']),
};

export default Service;
