import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { withTranslation } from '../../i18n';
import { mapService } from '../../utils/service';

const IconWrapper = styled.div`
    width: ${(props) => (props.size === 'md' ? '1.5rem' : '2.5rem')};
    height: ${(props) => (props.size === 'md' ? '1.5rem' : '2.5rem')};
    color: ${(props) => props.service.color};
    background-color: ${(props) => props.service.backgroundColor};
    border: 1px solid ${(props) => props.service.borderColor};
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    svg {
        width: ${(props) => (props.size === 'md' ? '1em' : '1.5rem')};
        height: ${(props) => (props.size === 'md' ? '1rem' : '1.5rem')};
    }

    ${breakpoint('md')`
    margin-top: 0.2rem;
  `};

    @media (min-width: 768px) {
        line-height: 24px;
    }
`;

const Icon = (props) => {
    const { service, size = 'lg' } = props;
    const mappedService = mapService(service.id);

    return (
        <IconWrapper size={size} service={mappedService}>
            {mappedService.serviceIcon}
        </IconWrapper>
    );
};

export default withTranslation('services')(Icon);
