import React from 'react';
import PropTypes from 'prop-types';
import { ServiceDescription } from './Service';
import DescriptionText from './Descriptions/DescriptionText';
import PriceAdditionalPetDescriptionText from './Descriptions/PriceAdditionalPetDescriptionText';
import PriceSecondDailyVisitDescriptionText from './Descriptions/PriceSecondDailyVisitDescriptionText';

const FullDescription = (props) => {
    const { service, additionalPet, secondDailyVisit, currency } = props;

    return (
        <ServiceDescription>
            <DescriptionText service={service} />
            {additionalPet && (
                <div>
                    <PriceAdditionalPetDescriptionText price={additionalPet} currency={currency} />
                </div>
            )}
            {secondDailyVisit && (
                <div>
                    <PriceSecondDailyVisitDescriptionText price={secondDailyVisit} currency={currency} />
                </div>
            )}
        </ServiceDescription>
    );
};

FullDescription.propTypes = {
    service: PropTypes.object,
    currency: PropTypes.string,
};

export default FullDescription;
