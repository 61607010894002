import React from 'react';
import styled from 'styled-components';
import HomepageDescription from './HomepageDescription';
import DropdownLabelText from './Labels/DropdownLabelText';

export const Wrapper = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
`;

const Title = styled.h3`
    line-height: normal;
`;

const LabelWithHomepageDescription = (props) => {
    const { className } = props;

    return (
        <Wrapper className={className}>
            <Title>
                <DropdownLabelText {...props} />
            </Title>
            <HomepageDescription {...props} />
        </Wrapper>
    );
};

export default LabelWithHomepageDescription;
