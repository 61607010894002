import React from 'react';
import styled from 'styled-components';
import ShortDescription from './ShortDescription';
import DropdownLabelText from './Labels/DropdownLabelText';
import ExistingClientsOnly from './Labels/ExistingClientsOnly';

export const Wrapper = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
`;

const Container = styled.span`
    margin-left: 0.5rem;
`;

const LabelWithShortDescriptionUnderneathAndExistingClientsOnly = (props) => {
    return (
        <Wrapper>
            <div>
                <DropdownLabelText {...props} />
                {props.existingClientsOnly && (
                    <Container>
                        <ExistingClientsOnly />
                    </Container>
                )}
            </div>

            <ShortDescription {...props} />
        </Wrapper>
    );
};

export default LabelWithShortDescriptionUnderneathAndExistingClientsOnly;
