import React from 'react';
import styled from 'styled-components';
import FullDescription from './FullDescription';
import LabelText from './Labels/LabelText';

export const Wrapper = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
`;

const LabelWithFullDescription = (props) => {
    const { className } = props;

    return (
        <Wrapper className={className}>
            <LabelText {...props} />
            <FullDescription {...props} />
        </Wrapper>
    );
};

export default LabelWithFullDescription;
