import React from 'react';
import styled from 'styled-components';
import HomepageDescriptionText from './Descriptions/HomepageDescriptionText';

const Description = styled.div`
    color: #939393;
    margin-top: 0.5rem;
`;

const HomepageDescription = (props) => {
    const { service } = props;

    return (
        <Description>
            <HomepageDescriptionText service={service} />
        </Description>
    );
};

export default HomepageDescription;
