import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from '../../../i18n';

const PriceAdditionalPetDescriptionText = (props) => {
    const { t, price, currency } = props;
    return t('priceAdditionalPet', { price: price + ' ' + currency });
};

PriceAdditionalPetDescriptionText.propTypes = {
    currency: PropTypes.string.isRequired,
};

export default withTranslation('finance')(PriceAdditionalPetDescriptionText);
