import React from 'react';
import styled from 'styled-components';
import FullDescription from './FullDescription';
import LabelText from './Labels/LabelText';
import ExistingClientsOnly from './Labels/ExistingClientsOnly';

export const Wrapper = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
`;

const Container = styled.span`
    margin-left: 0.5rem;
`;

const LabelWithFullDescriptionUnderneathAndExistingClientsOnly = (props) => {
    const { className } = props;

    return (
        <Wrapper className={className}>
            <div>
                <LabelText {...props} />
                {props.existingClientsOnly && (
                    <Container>
                        <ExistingClientsOnly />
                    </Container>
                )}
            </div>
            <FullDescription {...props} />
        </Wrapper>
    );
};

export default LabelWithFullDescriptionUnderneathAndExistingClientsOnly;
