import React from 'react';
import PropTypes from 'prop-types';
import { ServiceDescription } from './Service';
import ShortDescriptionText from './Descriptions/ShortDescriptionText';
import PriceAdditionalPetDescriptionText from './Descriptions/PriceAdditionalPetDescriptionText';
import PriceSecondDailyVisitDescriptionText from './Descriptions/PriceSecondDailyVisitDescriptionText';

const ShortDescription = (props) => {
    const { service, additionalPet, secondDailyVisit, currency } = props;

    return (
        <ServiceDescription>
            <ShortDescriptionText service={service} />
            {additionalPet && (
                <div>
                    <PriceAdditionalPetDescriptionText price={additionalPet} currency={currency} />
                </div>
            )}
            {secondDailyVisit && (
                <div>
                    <PriceSecondDailyVisitDescriptionText price={secondDailyVisit} currency={currency} />
                </div>
            )}
        </ServiceDescription>
    );
};

ShortDescription.propTypes = {
    service: PropTypes.object,
};

export default ShortDescription;
