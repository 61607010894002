import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from '../../../i18n';

const PriceSecondDailyVisitDescriptionText = (props) => {
    const { t, price, currency } = props;
    return t('priceSecondDailyVisit', {
        price: price + ' ' + currency,
    });
};

PriceSecondDailyVisitDescriptionText.propTypes = {
    currency: PropTypes.string.isRequired,
};

export default withTranslation('finance')(PriceSecondDailyVisitDescriptionText);
