import { doggyDayCare, dogWalking, homeDogBoarding, homeVisitsService, houseSitting } from '../Pawshake/Core/Service';
import { oneHomeVisitService, twoHomeVisitsService } from '../Pawshake/Core/Service/BookableService';

export const getBookableServiceIcon = (bookableService) => {
    return getServiceIcon(bookableService.id);
};

export const getServiceIcon = (serviceId) => {
    switch (serviceId) {
        case doggyDayCare:
            return (
                <svg viewBox='0 0 24 24'>
                    <path
                        fill='currentColor'
                        d='M12 8.327a3.673 3.673 0 1 0 0 7.346 3.673 3.673 0 0 0 0-7.346ZM5.878 12a6.122 6.122 0 1 1 12.244 0 6.122 6.122 0 0 1-12.244 0ZM12 0c.676 0 1.225.548 1.225 1.224v1.96a1.224 1.224 0 1 1-2.45 0v-1.96C10.775.548 11.324 0 12 0ZM12 19.592c.676 0 1.225.548 1.225 1.224v1.96a1.224 1.224 0 1 1-2.45 0v-1.96c0-.676.549-1.224 1.225-1.224ZM3.513 3.513a1.224 1.224 0 0 1 1.732 0l1.39 1.391a1.224 1.224 0 0 1-1.731 1.732L3.513 5.245a1.224 1.224 0 0 1 0-1.732ZM17.364 17.364a1.224 1.224 0 0 1 1.732 0l1.391 1.391a1.224 1.224 0 1 1-1.732 1.732l-1.39-1.39a1.224 1.224 0 0 1 0-1.733ZM0 12c0-.676.548-1.225 1.224-1.225h1.96a1.224 1.224 0 1 1 0 2.45h-1.96A1.224 1.224 0 0 1 0 12ZM19.592 12c0-.676.548-1.225 1.224-1.225h1.96a1.224 1.224 0 1 1 0 2.45h-1.96A1.225 1.225 0 0 1 19.592 12ZM6.636 17.364a1.225 1.225 0 0 1 0 1.732l-1.391 1.391a1.224 1.224 0 1 1-1.732-1.732l1.391-1.39a1.224 1.224 0 0 1 1.732 0ZM20.487 3.513a1.224 1.224 0 0 1 0 1.732l-1.39 1.39a1.225 1.225 0 0 1-1.733-1.731l1.391-1.391a1.225 1.225 0 0 1 1.732 0Z'
                    />
                </svg>
            );
        case dogWalking:
            return (
                <svg viewBox='0 0 24 24'>
                    <path
                        fill='currentColor'
                        d='M13 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM13.978 12.27c.245.368.611.647 1.031.787l1.727.576a1 1 0 0 0 .632-1.896l-1.726-.576-1.663-2.495a2.016 2.016 0 0 0-.769-.679l-1.434-.717a1.989 1.989 0 0 0-1.378-.149l-3.193.797a2.002 2.002 0 0 0-1.306 1.046l-1.347 2.695a1 1 0 0 0 1.79.894L7.687 9.86l2.223-.556-1.804 8.346-2.85 1.96a1 1 0 0 0 1.133 1.648l2.851-1.961c.421-.29.713-.725.82-1.225l.517-2.388 2.517 1.888.729 3.644a1 1 0 1 0 1.961-.392l-.729-3.647a2 2 0 0 0-.762-1.206l-2.171-1.628.647-3.885 1.208 1.813Z'
                    />
                </svg>
            );
        case homeDogBoarding:
            return (
                <svg viewBox='0 0 24 24'>
                    <path
                        fill='currentColor'
                        d='M4 8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H4ZM1 9a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V9Z'
                    />
                    <path
                        fill='currentColor'
                        d='M7.879 2.879A3 3 0 0 1 10 2h4a3 3 0 0 1 3 3v16a1 1 0 1 1-2 0V5a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v16a1 1 0 1 1-2 0V5a3 3 0 0 1 .879-2.121Z'
                    />
                </svg>
            );
        case houseSitting:
            return (
                <svg viewBox='0 0 24 24'>
                    <path
                        fill='currentColor'
                        d='M12.3 2.386c.242.43.209.96-.084 1.357a5.75 5.75 0 0 0 8.042 8.042 1.25 1.25 0 0 1 1.988 1.12 10.25 10.25 0 1 1-11.15-11.15 1.25 1.25 0 0 1 1.204.631ZM8.915 4.87A7.75 7.75 0 1 0 19.13 15.086 8.248 8.248 0 0 1 8.613 7.774a8.249 8.249 0 0 1 .302-2.904Z'
                    />
                </svg>
            );
        default:
            return (
                <svg viewBox='0 0 24 24'>
                    <path
                        fill='currentColor'
                        d='M7.5 11a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9ZM1 15.5a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Z'
                    />
                    <path
                        fill='currentColor'
                        d='M21.707 1.293a1 1 0 0 1 0 1.414l-9.6 9.6a1 1 0 0 1-1.414-1.414l9.6-9.6a1 1 0 0 1 1.415 0Z'
                    />
                    <path
                        fill='currentColor'
                        d='M18.293 3.293a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414l-3.5 3.5a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L18.5 9.086 20.586 7l-2.293-2.293a1 1 0 0 1 0-1.414Z'
                    />
                </svg>
            );
    }
};

export const fromBookableServiceToService = (bookableService) => {
    if ([oneHomeVisitService.id, twoHomeVisitsService.id].includes(bookableService.id)) {
        return homeVisitsService;
    }

    return bookableService;
};

const colorForService = (serviceId) => {
    switch (serviceId) {
        case 'catSitting':
            return '#805AD5';
        case 'dogSitting':
            return '#805AD5';
        case 'dogWalking':
            return '#DF6C20';
        case 'doggyDayCare':
            return '#22c55e';
        case 'homeVisits':
        case 'oneHomeVisit':
        case 'twoHomeVisits':
            return '#805AD5';
        case 'petBoarding':
            return '#1f74ad';
        case 'petSitting':
            return '#805AD5';
        case 'sleepover':
            return '#D53F8C';
        default:
            return '#1f74ad';
    }
};

const backgroundColorForService = (serviceId) => {
    switch (serviceId) {
        case 'catSitting':
            return '#FAF5FF';
        case 'dogSitting':
            return '#FAF5FF';
        case 'dogWalking':
            return '#FCF0E9';
        case 'doggyDayCare':
            return '#f0fdf4';
        case 'homeVisits':
        case 'oneHomeVisit':
        case 'twoHomeVisits':
            return '#FAF5FF';
        case 'petBoarding':
            return '#e9f4fb';
        case 'petSitting':
            return '#FAF5FF';
        case 'sleepover':
            return '#FFF5F7';
        default:
            return '#e9f4fb';
    }
};

const borderColorForService = (serviceId) => {
    switch (serviceId) {
        case 'catSitting':
            return '#E9D8FD';
        case 'dogSitting':
            return '#E9D8FD';
        case 'dogWalking':
            return '#F6D6C1';
        case 'doggyDayCare':
            return '#bbf7d0';
        case 'homeVisits':
        case 'oneHomeVisit':
        case 'twoHomeVisits':
            return '#E9D8FD';
        case 'petBoarding':
            return '#c2e0f4';
        case 'petSitting':
            return '#E9D8FD';
        case 'sleepover':
            return '#FED7E2';
        default:
            return '#c2e0f4';
    }
};

export const mapService = (serviceId) => {
    return {
        serviceIcon: getServiceIcon(serviceId),
        backgroundColor: backgroundColorForService(serviceId),
        borderColor: borderColorForService(serviceId),
        color: colorForService(serviceId),
    };
};
