import React from 'react';
import styled from 'styled-components';
import ShortDescription from './ShortDescription';
import DropdownLabelText from './Labels/DropdownLabelText';

export const Wrapper = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
`;

const LabelWithShortDescriptionUnderneath = (props) => {
    return (
        <Wrapper>
            <DropdownLabelText {...props} />
            <ShortDescription {...props} />
        </Wrapper>
    );
};

export default LabelWithShortDescriptionUnderneath;
