import React from 'react';
import styled from 'styled-components';
import { withTranslation } from '../../i18n';

const Wrapper = styled.div`
    min-width: 3rem;
    text-align: right;
`;

const Divider = styled.span`
    margin-left: 0.25rem;
    margin-right: 0.25rem;
`;

const Price = (props) => {
    const { t, amount, currency, service, className } = props;

    return (
        <Wrapper className={className}>
            <span>{`${amount} ${currency}`}</span>
            <div>
                <Divider>/</Divider>
                {t(`services.${service.id}.suffix`)}
            </div>
        </Wrapper>
    );
};

export default withTranslation('services')(Price);
