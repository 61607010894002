import React from 'react';
import styled from 'styled-components';
import SVGInline from 'react-svg-inline';

const Icon = styled.div`
    width: 1.5rem;
    margin: auto;
`;

const CheckMark = ({ className }) => {
    return (
        <Icon className={className}>
            <SVGInline
                svg={
                    '<svg width="18" height="15" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-1 -1)" fill-rule="nonzero" fill="#00BF8E"><rect transform="rotate(35 13.158 8.5)" x="11.158" width="4" height="17" rx="2"/><rect transform="scale(-1 1) rotate(53 0 .28)" x="3.842" y="6.996" width="4" height="10" rx="2"/></g></svg> '
                }
            />
        </Icon>
    );
};

export default CheckMark;
